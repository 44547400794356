export class CommonFunctions {

  /**
   * get browser's locale
   */
  static GetBrowserLocale() {
    return navigator.language;
  }

  /**
   * get time string and convert it to 24 hours time format
   * @param timeString | time as a string
   * @returns string (as 24 hours format)
   */
  static Get24HoursTimeFormat(timeString: string) {
    if (timeString) {
      const hours = timeString.toString().substring(0, 2);
      const minutes = timeString.toString().substring(2, 4);
      return hours + ':' + minutes + ':' + '00';
    }
  }

}
