import { CommonUIService } from './../../../common/services/common.ui.service';
import { DoctorGender } from 'src/app/models/doctor/doctor-gender.model';
import { AuxillaryService } from 'src/app/sections/common/services/auxillary.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { Doctor } from '../../../../models/doctor/doctor.model';
import { GeneralInformationService } from '../services/general-information.service';
import { Province } from '../../../../models/doctor/province.model';
import { Nationality } from '../../../../models/doctor/nationality.model';
import { DoctorService } from '../../services/doctor.service';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { changeNgbDateToCustomFormat } from '../../../common/services/change-ngb-date-to-custom-format';
import { SortList } from '../../../../sections/common/sortList';
import { Issuer } from '../../../../models/auxiliary/issuer.model';
import { IssuerService } from '../../../../sections/auxillary-tables/issuer/services/issuer.service';
import * as moment from 'moment';
import { Town } from 'src/app/models/doctor/doctor-town.model';
import { DropdownService } from 'src/app/sections/common/services/dropdown.service';
import { SweetAlertActions } from 'src/app/constants/sweet-alert-actions';
import { AuxiliaryTranslateValue } from 'src/app/models/common/auxiliary-translate-values';
import { DropDownOption } from 'src/app/models/common/dropdown-option';
import { ToastrService } from 'ngx-toastr';
enum DocType {
  NIF = 1,
  NIE = 2,
  Passport = 3
}
@Component({
  selector: 'app-edit-general-information',
  templateUrl: './edit-general-information.component.html',
  styleUrls: ['./edit-general-information.component.scss']
})


export class EditGeneralInformationComponent implements OnInit {
  @Input() generalInformation: Doctor;
  @Input() title: string;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild('modalLargeNew', { static: false }) child;
  @ViewChild('birthTownRef', { static: false }) birthTownRef;

  generalInformationForm: FormGroup;
  townsList: Town[] = [];
  provinces: Province[] = [];
  nationlities: Nationality[] = [];
  genders: DoctorGender[] = [];
  generalInformationData: Doctor = {} as Doctor;
  auxiliaryTranslateValue: AuxiliaryTranslateValue
  birthTownControl = new UntypedFormControl();
  ngDocumentTypeList: DropDownOption[] = [];
  private isImageChanged = false;
  private imageFile: any;
  private image: any;
  submitted = false;
  isEdit = false;
  issuer: Issuer;
  latestCollegiateNumber: number;
  newCollegiateNumber = 0;
  selectedDocument: number = 0;

  minDate: object = { year: 1900, month: 1, day: 1 };
  maxDate: object = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };

  initialValue: 5000;

  validationMessages = {
    birthOfDate: {
      ngbDate: {
        invalid: 'common.validations.invalidDate',
        requiredAfter: 'common.validations.pastDate',
      }
    },
    birthTownId: {
      optionNotSelected: 'digitValidate',
    }
  }

  constructor(private formBuilder: UntypedFormBuilder, private generalInformationService: GeneralInformationService, private commonUIService: CommonUIService,
    private doctorService: DoctorService, private toastService: ToastrService, private router: Router, private dropdownService: DropdownService,
    private issuerService: IssuerService, private translationService: TranslateService, private auxillaryService: AuxillaryService) {
    this.generalInformationData = new Doctor();
    this.auxiliaryTranslateValue = new AuxiliaryTranslateValue();
  }

  ngOnInit() {
    this.getAuxiliaryValues();
    this.initializeForAddGeneralInformation();
    this.getDefaultIssuer();
  }

  getAuxiliaryValues() {
    this.getTownData();
    this.getProvinceData();
    this.getNationalityData();
    this.getGenderData();
  }

  getProvinceData() {
    this.generalInformationService.getProvinces().subscribe(
      (data: any) => {
        this.provinces = SortList(data._data, 'provinceName');
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  getTownData() {
    this.dropdownService.getTownList().subscribe(
      (data: any) => {
        this.townsList = SortList(data._data, 'townName');
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }


  getNationalityData() {
    this.generalInformationService.getNationalists().subscribe(
      (data: any) => {

        this.nationlities = SortList(data._data, 'nationalityName');
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  getGenderData() {
    this.auxillaryService.getGenderList().subscribe(
      (data: any) => {
        if (data._data != null) {
          this.genders = data._data.filter(gender => gender.status);
        }
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  async getDefaultIssuer() {
    return new Promise<void>((resolve, reject) => {
      this.issuerService.getDefaultIssuer().subscribe(
        (data: any) => {
          if (data._data != null) {
            this.issuer = data._data;
          }
          resolve();
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  getLatestCollegiateNumber(cp: number) {
    this.doctorService.getLatestCollegiateNumber(cp).subscribe(
      (data: any) => {
        if (data._data != null) {
          this.latestCollegiateNumber = Number(data._data);
          if (this.latestCollegiateNumber == 0) {
            const cpvalue = this.issuer.cp.toString();
            const value = cpvalue.slice(0, 2);
            const hhh = Number(value + '00');
            const initialValue = 5000;
            this.newCollegiateNumber = Number(hhh.toString() + initialValue.toString());
          } else {
            this.newCollegiateNumber = (this.latestCollegiateNumber + 1);
          }
        }
      }
    );
  }

  //------------------On change methods--------------------------------------
  onChangeTown(town) {
    if (town) {
      this.generalInformationForm.controls.birthTownId.setValue(Number(town.id));
      this.generalInformationForm.controls.birthProvinceId.setValue(Number(town.provinceId));
      this.generalInformationForm.controls.birthProvinceName.setValue(town.province.provinceName);
      this.generalInformationData.birthProvince = null;
    }
  }

  onChangeProvince($event) {
    if (this.generalInformationForm.controls.birthProvinceId.value) {
      this.generalInformationForm.controls.birthTownId.setValue(null);
      this.generalInformationData.birthTown = null;
      this.birthTownControl.setValue('');
      this.birthTownRef.nativeElement.click();
    } else {
      this.generalInformationData.birthProvince = null;
    }
  }

  onClearTown() {
    this.birthTownControl.setValue(null);
    this.generalInformationForm.controls.birthTownId.setValue(null);
    this.generalInformationForm.controls.birthProvinceId.setValue(null);
    this.generalInformationForm.controls.birthProvinceName.setValue(null);
    this.generalInformationData.birthTown = null;
    this.generalInformationData.birthProvince = null;
  }

  async checkIfEditOrAdd() {
    await this.getDefaultIssuer();
    if (!this.generalInformationData || !this.generalInformationData.id) {
      this.getLatestCollegiateNumber(this.issuer.cp);
      this.initializeForAddGeneralInformation();
      this.isEdit = true;
      return;
    }
    this.initializeForEditGeneralInformation();
    this.newCollegiateNumber = this.generalInformationData.collegiateNumber;
    this.isEdit = false;
  }

  initializeForAddGeneralInformation() {
    this.generalInformationForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      surname1: ['', [Validators.required]],
      surname2: [''],
      birthOfDate: [new Date(), [Validators.required]],
      documentTypeId: [1, [Validators.required]],
      documentTypeValue: ['', [Validators.required]],
      genderId: [null],
      nif: [''],
      nie: [''],
      passport: [''],
      birthProvinceId: [null],
      birthProvinceName: [null],
      birthTownId: [null],
      nationalityByBirthId: [null],
      currentNationalityId: [null],
      doctorRegisteredDate: [new Date(), [Validators.required]],
    });
  }

  initializeForEditGeneralInformation() {
    const date = moment(this.generalInformationData.birthOfDate).format('YYYY-MM-DD');

    this.generalInformationForm = this.formBuilder.group({
      name: [this.generalInformationData.name, [Validators.required]],
      surname1: [this.generalInformationData.surname1, [Validators.required]],
      surname2: [this.generalInformationData.surname2],
      birthOfDate: [date, [Validators.required]],
      documentTypeValue: ['', [Validators.required]],
      genderId: [this.generalInformationData.genderId || null],
      documentTypeId: [this.generalInformationData.documentTypeId || 1, [Validators.required]],
      nif: [this.generalInformationData.nif],
      nie: [this.generalInformationData.nie],
      passport: [this.generalInformationData.passport],
      birthProvinceId: this.generalInformationData.birthProvinceId,
      birthProvinceName: this.generalInformationData.birthProvince ? this.generalInformationData.birthProvince.provinceName : '',
      birthTownId: this.generalInformationData.birthTownId,
      nationalityByBirthId: [this.generalInformationData.nationalityByBirthId],
      currentNationalityId: [this.generalInformationData.currentNationalityId],
      collegiateNumber: [this.generalInformationData.collegiateNumber],
      doctorRegisteredDate: [this.generalInformationData.doctorRegisteredDate ? moment(this.generalInformationData.doctorRegisteredDate).format('YYYY-MM-DD') : null, [Validators.required]],
    });

    if (this.generalInformationData.documentTypeId != null) {
      if (this.generalInformationData.documentTypeId == DocType.NIF) {
        this.generalInformationForm.get('documentTypeValue').setValue(this.generalInformationData.nif);
      } else if (this.generalInformationData.documentTypeId == DocType.NIE) {
        this.generalInformationForm.get('documentTypeValue').setValue(this.generalInformationData.nie);
      } else if (this.generalInformationData.documentTypeId == DocType.Passport) {
        this.generalInformationForm.get('documentTypeValue').setValue(this.generalInformationData.passport);
      }
      this.generalInformationForm.updateValueAndValidity()
    } else {
      this.generalInformationForm.get('documentTypeValue').setValue(this.generalInformationData.nif)
      this.generalInformationForm.updateValueAndValidity();
    }
  }

  public show(){
    if (localStorage.getItem('locale')) {
      const lang = localStorage.getItem('locale');
      this.generalInformationService.localeId = lang;
    }
    const self = this;
    self.getAuxiliaryValues();

    setTimeout(() => {
      this.submitted = false;
      if (self.generalInformation && self.generalInformation.id) {
        self.generalInformationData = JSON.parse(JSON.stringify(self.generalInformation));
        if (self.generalInformationData.imagePathUrl) {
          self.generalInformationData.imagePathUrl = 'data:image/jpg;base64,' + self.generalInformationData.imagePathUrl;
        }
      } else {
        this.generalInformationData = new Doctor();
      }
      self.isImageChanged = false;
      this.generalInformationForm.reset();
      self.checkIfEditOrAdd();
      if (this.generalInformationData && this.generalInformationData.birthTown) {
        this.birthTownControl.setValue(this.generalInformationData.birthTown.townName);
        this.birthTownRef.nativeElement.click();
      }
      self.child.show();

    }, 500);
  }

  closeModal() {
    this.child.hide();
    this.generalInformationForm.reset();
  }

  handleUpdateItemClick(titleKey: string, messageKey: string, data: any) {
    const nameChanged = this.generalInformationForm.controls.name.dirty;
    const surname1Changed = this.generalInformationForm.controls.surname1.dirty;
    const surname2Changed = this.generalInformationForm.controls.surname2.dirty;
    const documentTypeValueChanged = this.generalInformationForm.controls.documentTypeValue.dirty;

    if ((nameChanged || surname1Changed || surname2Changed || documentTypeValueChanged) && this.generalInformationData.hasDocuments) {
      this.commonUIService.sweetAlert(titleKey, messageKey, null, data).then((response: any) => {
        if (response.action == SweetAlertActions.confirm) this.onSave('clicked');
      })
    }
    else {
      this.onSave('clicked');
    }
  }

  async onSave(data) {
    if (data !== 'clicked') {
      return;
    }
    this.submitted = true;

    if (!this.commonUIService.validateForm(this.generalInformationForm, this.validationMessages)) {
      return;
    } else {
      this.selectedDocument = this.generalInformationForm.get('documentTypeId').value;
      if (this.selectedDocument == DocType.NIF) {
        this.generalInformationData.nif = this.generalInformationForm.get('documentTypeValue').value;
        this.generalInformationForm.get('nif').setValue(this.generalInformationData.nif);
      } else if (this.selectedDocument == DocType.NIE) {
        this.generalInformationData.nie = this.generalInformationForm.get('documentTypeValue').value;
        this.generalInformationForm.get('nie').setValue(this.generalInformationData.nie);
      } else if (this.selectedDocument == DocType.Passport) {
        this.generalInformationData.passport = this.generalInformationForm.get('documentTypeValue').value;
        this.generalInformationForm.get('passport').setValue(this.generalInformationData.passport);
      }
    }

    if (this.generalInformationData) {
      this.generalInformationData.name = this.generalInformationForm.value.name;
      this.generalInformationData.surname1 = this.generalInformationForm.value.surname1;
      this.generalInformationData.surname2 = this.generalInformationForm.value.surname2;

      this.generalInformationData.genderId = this.generalInformationForm.value.genderId;
      if (this.generalInformationData.gender) this.generalInformationData.gender.id = this.generalInformationForm.value.genderId;

      this.generalInformationData.birthOfDate = changeNgbDateToCustomFormat(this.generalInformationForm.value.birthOfDate).toString();

      this.generalInformationData.birthProvinceId = this.generalInformationForm.value.birthProvinceId;
      if (this.generalInformationData.birthProvince) this.generalInformationData.birthProvince.id = this.generalInformationForm.value.birthProvinceId;

      this.generalInformationData.birthTownId = this.generalInformationForm.value.birthTownId;
      if (this.generalInformationData.birthTown) this.generalInformationData.birthTown.id = this.generalInformationForm.value.birthTownId;

      this.generalInformationData.nationalityByBirthId = this.generalInformationForm.value.nationalityByBirthId;
      if (this.generalInformationData.nationalityByBirth) this.generalInformationData.nationalityByBirth.id = this.generalInformationForm.value.nationalityByBirthId;

      this.generalInformationData.currentNationalityId = this.generalInformationForm.value.currentNationalityId;
      if (this.generalInformationData.currentNationality) this.generalInformationData.currentNationality.id = this.generalInformationForm.value.currentNationalityId;

      this.generalInformationData.collegiateNumber = this.newCollegiateNumber;

      this.generalInformationData.doctorRegisteredDate = (!this.generalInformationForm.value.doctorRegisteredDate) ? null : changeNgbDateToCustomFormat(this.generalInformationForm.value.doctorRegisteredDate);

      this.generalInformationData.country = null
      this.generalInformationData.birthProvince = null
      this.generalInformationData.birthTown = null
      this.generalInformationData.town = null
      this.generalInformationData.currentNationality = null
      this.generalInformationData.gender = null
      this.generalInformationData.nationalityByBirth = null
      this.generalInformationData.province = null
    }


    if (this.generalInformationData && this.generalInformationData.id) {
      if (this.isImageChanged) {
        const imageName = await this.uploadProfileImage();
        this.generalInformationData.imageName = imageName.toString();
      }
      this.updateDoctorData();
      return;
    }
    this.saveDoctorData(this.generalInformationForm.value);
  }

  async uploadProfileImage() {
    this.createFileObject();
    return new Promise((resolve, reject) => this.generalInformationService.uploadImage(this.generalInformationData.id.toString(), 'Doctor', 'uploadImage', this.imageFile).subscribe(
      (data: any) => {
        if (data != null && data.hasOwnProperty('body')) {
          this.successToast('successPhotoUploadMessage');
          resolve(data.body.data);
          this.child.hide();
        }
      },
      (error) => {
        this.failToast(error._message);
        reject(false);
      }
    ));
  }

  saveDoctorData(docData: Doctor) {
    this.doctorService.isReload = false;
    docData.birthOfDate = changeNgbDateToCustomFormat(docData.birthOfDate);
    docData.doctorRegisteredDate = changeNgbDateToCustomFormat(docData.doctorRegisteredDate);
    this.generalInformationService.updateHeaderCarousel(true);
    docData.collegiateNumber = this.newCollegiateNumber;
    this.doctorService.saveDoctorDetails(docData).subscribe(
      async (data: any) => {
        this.successToast('successGeneralMessage');
        this.generalInformationData = data._data;
        if (this.isImageChanged) {
          const imageName = await this.uploadProfileImage();
          this.generalInformationData.imageName = imageName.toString();
          this.updateDoctorData();
        }
        if (this.doctorService.isCurrentPage) {
          this.doctorService.isReload = true;
        }
        this.close.emit(data._data.id);
        this.router.navigate(['/doctor/doctor-details/' + data._data.id]);
        this.child.hide();
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  updateDoctorData() {
    this.generalInformationData.collegiateNumber = this.newCollegiateNumber;
    this.generalInformationData.genderId = this.generalInformationForm.value.genderId;

    this.selectedDocument = this.generalInformationForm.get('documentTypeId').value;
    this.generalInformationData.documentTypeId = this.selectedDocument;
    if (this.selectedDocument == DocType.NIF) {
      this.generalInformationData.nif = this.generalInformationForm.get('documentTypeValue').value;
      this.generalInformationData.nie = "";
      this.generalInformationData.passport = "";
    } else if (this.selectedDocument == DocType.NIE) {
      this.generalInformationData.nie = this.generalInformationForm.get('documentTypeValue').value;
      this.generalInformationData.passport = "";
      this.generalInformationData.nif = "";
    } else if (this.selectedDocument == DocType.Passport) {
      this.generalInformationData.passport = this.generalInformationForm.get('documentTypeValue').value;
      this.generalInformationData.nie = "";
      this.generalInformationData.nif = "";
    }

    this.doctorService.updateDoctorDetails(this.generalInformationData).subscribe(
      (data: any) => {
        this.generalInformationData = null;
        this.successToast('successGeneralUpdateMessage');
        if (data != null)
          this.close.emit(data._data.id);
        this.generalInformationService.updateHeaderCarousel(true);
        this.child.hide();
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  changeImage(image) {
    this.isImageChanged = true;
    this.generalInformationData.imagePathUrl = image.base64.toString();
    this.image = image;
  }

  createFileObject() {
    const fileName = new Date().toISOString().replace('.', '').substring(0, 10);
    const imageName = fileName + '.' + this.image.file.type.split('/')[1];
    const imageFile = this.dataURItoBlob(this.image.base64.replace(/^data:image\/(png|jpg);base64,/, ''));
    this.imageFile = new File([imageFile], imageName, { type: this.image.file.type });
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([int8Array], { type: 'image/jpeg' });
  }

  isBirthPlaceValueSelected(isValueSelected: any) {
    //value recieved, form group and control name
    this.commonUIService.novasearchDropDownValidator(isValueSelected, this.generalInformationForm, 'birthTownId');
  }

  successToast(messageTranslationKey) {
    this.translationService.get(['success', messageTranslationKey]).subscribe((translations: any) => {
      this.toastService.success(translations[messageTranslationKey], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }

  failToast(message) {
    this.translationService.get(['errorTitle', message]).subscribe((translations: any) => {
      this.toastService.error(translations[message], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }



  get birthTownId() {
    return this.generalInformationForm.get('birthTownId');
  }

  get name() {
    return this.generalInformationForm.get('name');
  }

  get nif() {
    return this.generalInformationForm.get('nif');
  }

  get nie() {
    return this.generalInformationForm.get('nie');
  }

  get birthOfDate() {
    return this.generalInformationForm.get('birthOfDate');
  }

  get surname1() {
    return this.generalInformationForm.get('surname1');
  }

  get surname2() {
    return this.generalInformationForm.get('surname2');
  }

  get doctorRegisteredDate() {
    return this.generalInformationForm.get('doctorRegisteredDate');
  }

  get documentTypeValue() {
    return this.generalInformationForm.get('documentTypeValue');
  }

  get documentTypeId() {
    return this.generalInformationForm.get('documentTypeId');
  }

  get genderId() {
    return this.generalInformationForm.get('genderId');
  }

  get passport() {
    return this.generalInformationForm.get('passport');
  }

  get birthProvinceId() {
    return this.generalInformationForm.get('birthProvinceId');
  }

  get birthProvinceName() {
    return this.generalInformationForm.get('birthProvinceName');
  }

  get nationalityByBirthId() {
    return this.generalInformationForm.get('nationalityByBirthId');
  }

  get currentNationalityId() {
    return this.generalInformationForm.get('currentNationalityId');
  }

  get collegiateNumber() {
    return this.generalInformationForm.get('collegiateNumber');
  }

}
